<template>
  <div>
    <!-- 上市文件 -->
    <div class="banner" id="investor_banner1" :style="{backgroundImage:('url('+banner+')')}">
      <!-- <img :src="banner" :alt="$t('indexnav.Investor Relations')" /> -->
      <span
        >{{ $t("indexnav.Listing Documents")
        }}<i
          :style="{
            display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
          }"
          >Listing Documents</i
        ></span
      >
    </div>
    <!-- 跳转 -->
    <section class="ab-item1">
      <!-- <a href="/">{{ $t("indexnav.Home") }}</a> >
      <a href="/ir/default.html">{{ $t("indexnav.Investor Relations") }}</a> >
      <a>{{ $t("indexnav.Listing Documents") }}</a> -->
      <bread-crumb />
    </section>
    <section>
      <div id="iframeContainer"></div>
    </section>
    <div class="container" v-if="false">
      <div class="loading_box" v-if="showLoading"></div>
      <!-- 简体 -->
      <ul class="ir-prosp" v-if="nowlanguage == 'sample'">
        <li v-for="item in dataList" :key="item.id">
          <dl>
            <dt>
              <a :href="item.sampleFileUrl" target="_blank"
                ><img :src="item.sampleImgUrl" v-if="item.sampleImgUrl" />
                <!-- <img src="../../assets/images/loading/2c6b6978248c8cd784a2ad3f2c9ef3b6.jpeg" alt="" v-else> -->
              </a>
            </dt>
          </dl>
        </li>
      </ul>
      <!-- 繁体 -->
      <ul class="ir-prosp" v-if="nowlanguage == 'tranditional'">
        <li v-for="item in dataList" :key="item.id">
          <dl>
            <dt>
              <a :href="item.tranditionalFileUrl" target="_blank"
                ><img
                  :src="item.tranditionalImgUrl"
                  v-if="item.tranditionalImgUrl"
                />
                <!-- <img src="../../assets/images/loading/2c6b6978248c8cd784a2ad3f2c9ef3b6.jpeg" alt="" v-else> -->
              </a>
            </dt>
          </dl>
        </li>
      </ul>
      <!-- en -->
      <ul class="ir-prosp" v-if="nowlanguage == 'en'">
        <li v-for="item in dataList" :key="item.id">
          <dl>
            <dt>
              <a :href="item.enFileUrl" target="_blank"
                ><img :src="item.enImgUrl" v-if="item.enImgUrl" />
                <!-- <img src="../../assets/images/loading/2c6b6978248c8cd784a2ad3f2c9ef3b6.jpeg" alt="" v-else> -->
              </a>
            </dt>
          </dl>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ShangShiWenJian } from "@/api/investors";
import { PageType } from "@/utils/constant";
import { getAction } from "@/api/manage";
import { ifarmeMixin } from "../mixin/ifarmeMixin";

export default {
  mixins: [ifarmeMixin],
  data() {
    return {
      nowlanguage: "",
      dataList: [],
      showLoading: true,
      banner: "",
      izh: "https://jinxinfertility.website.wisdomir.com/sc/ir_prospectus.php",
      itr: "https://jinxinfertility.website.wisdomir.com/tc/ir_prospectus.php",
      ien: "https://jinxinfertility.website.wisdomir.com/en/ir_prospectus.php",
    };
  },
  created() {
    // 初始化banner
    let that = this;
    getAction("/jeecg-system/common/frontend/advertise/active_list", {
      typeCode: PageType.SHANGSHIWENJIAN,
    }).then((res) => {
      if (
        res.result.records instanceof Array &&
        res.result.records.length > 0
      ) {
         if (res.result.records[0].isMultipatLanguge!=='0') {
          if (this.$store.state.nowlanguage == "zh")
            that.banner = res.result.records[0].sampleImg;
          if (this.$store.state.nowlanguage == "tr")
            that.banner = res.result.records[0].tranditionalImg;
          if (this.$store.state.nowlanguage == "en")
            that.banner = res.result.records[0].enImg;
        }else{
        that.banner = res.result.records[0].sampleImg
        }
      }
    });

    ShangShiWenJian().then((res) => {
      this.showLoading = false;
      this.dataList = res.result;
      console.log(this.dataList);
    });
    if (this.$store.state.nowlanguage == "zh") {
      this.nowlanguage = "sample";
    }
    if (this.$store.state.nowlanguage == "tr") {
      this.nowlanguage = "tranditional";
    }
    if (this.$store.state.nowlanguage == "en") {
      this.nowlanguage = "en";
    }
  },
  methods: {},
  computed: {
    getVuexData() {
      return;
    },
  },
  watch: {},
  mounted() {
    if (this.$store.state.nowlanguage == "zh") {
      this.setIframe(this.izh);
    }
    if (this.$store.state.nowlanguage == "tr") {
      this.setIframe(this.itr);
    }
    if (this.$store.state.nowlanguage == "en") {
      this.setIframe(this.ien);
    }
  },
};
</script>

<style scoped>
header + a img {
  max-width: 100%;
  width: 100%;
}
.banner {
  position: relative;
}

.banner img {
  width: 100%;
  max-width: 100vw;
  display: block;
}

section,
article {
  margin: 1.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

.ab-item1 {
  padding: 0 0 1.5em 0;
  border-bottom: 1px solid #d2d2d2;
  font-size: 1.5rem;
  color: #595757;
}

article div {
  background: #eee;
  padding: 2.8em;
  display: flex;
  justify-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

article div a {
  width: 30%;
  text-align: center;
}

article div a img {
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.5);
}

article div a img:hover {
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 1);
}

article div a img {
  width: 80%;
}

#iframeContainer {
  margin: 3em auto 0 auto;
  padding: 0 0.5em;
  width: 100%;
  max-width: 1200px;
}

iframe {
  margin: 0.6em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

@media screen and (max-width: 1200px) {
  .ab-item1 {
    font-size: 1.2em;
    padding: 0 1.5em 1.5em 1.5em;
  }

  article {
    padding: 0 1.5em 1.5em 1.5em;
  }
}

@media screen and (max-width: 768px) {
  article div a {
    width: 100%;
    margin-bottom: 2.5em;
  }

  article div a img {
    width: 70%;
  }
}
.container {
  margin: auto;
  overflow: hidden;
  padding: 0 20px;
  margin-top: 4em;
  margin: 2.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
}
ul.ir-prosp {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  /* margin-left: ; */
  justify-content: center;
}
ul.ir-prosp li {
  width: 50%;
  display: table;
  position: relative;
  height: auto;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
}
ul.ir-prosp dl dt {
  position: relative;
  width: 100%;
  max-width: 300px;
  display: table-cell;
}
a {
  color: #666666;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
ul.ir-prosp dl dt a img {
  box-shadow: 2px 2px 15px rgb(0 0 0 / 50%);
}
ul.ir-prosp dl dt a img:hover {
  box-shadow: 2px 2px 15px rgb(0 0 0 / 80%);
}
img {
  border: none;
  width: 100%;
  height: auto;
}
</style>
